
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		name: { type: String, required: true },
		autofocus: { type: Boolean, required: false, default: false },
		modelValue: { type: String, required: false, default: '' },
	},
	computed: {
		inputVal: {
			get() {
				return this.modelValue;
			},
			set(val: string) {
				return this.$emit('update:modelValue', val);
			},
		},
	},
});
