
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		state: { type: String, required: false, default: 'success' },
	},
	computed: {
		alertColor() {
			if (this.state === 'success') return 'green';
			else if (this.state === 'error') return 'red';
		},
	},
});
