
import { UiTodoCard, UiToast } from '@/components';
import { liveQuery } from 'dexie';
import { useObservable } from '@vueuse/rxjs';
import { from } from 'rxjs';
import { db } from '@/db';
import { defineComponent } from 'vue';

export default defineComponent({
	data() {
		return { modal: 'none', selected: 0 };
	},
	components: { UiTodoCard, UiToast },
	methods: {
		toast(mode: string, id: number) {
			this.selected = id;
			if (['complete', 'incomplete', 'delete', 'edit'].includes(mode)) {
				this.modal = mode;
			}
		},
		remove() {
			this.selected && db.todos.delete(this.selected);
			this.reset();
		},
		edit() {
			this.selected && db.todos.update(this.selected, { content: ' Hatella' });
			this.reset();
		},
		toggle(eve: boolean) {
			db.todos.update(this.selected, { done: eve });
			this.reset();
		},
		reset() {
			this.modal = 'none';
			this.selected = 0;
		},
	},
	setup() {
		return {
			todos: useObservable(from(liveQuery(() => db.todos.reverse().toArray()))),
		};
	},
});
