import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce48ef02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container group trans" }
const _hoisted_2 = { class: "title trans" }
const _hoisted_3 = {
  key: 0,
  class: "content trans"
}
const _hoisted_4 = { class: "flex justify-around" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PencilAltIcon = _resolveComponent("PencilAltIcon")!
  const _component_TrashIcon = _resolveComponent("TrashIcon")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.data.title), 1),
    (_ctx.data.content)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.data.content), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_PencilAltIcon, {
        class: "edt-icn trans-30",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('pencil')))
      }),
      (_ctx.data.done)
        ? (_openBlock(), _createElementBlock("h4", {
            key: 0,
            class: "done trans-30",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('finish', 'incomplete')))
          }, " Completed "))
        : (_openBlock(), _createElementBlock("h4", {
            key: 1,
            class: "undone trans-30",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('finish', 'complete')))
          }, " Incomplete ")),
      _createVNode(_component_TrashIcon, {
        class: "dlt-icn trans-30",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('trash')))
      })
    ])
  ]))
}