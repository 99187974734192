import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53a95d4f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-emerald-600 dark:text-white text-lg font-medium"
}
const _hoisted_2 = {
  key: 1,
  class: "px-4 py-8 space-y-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiTodoCard = _resolveComponent("UiTodoCard")!
  const _component_UiToast = _resolveComponent("UiToast")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.todos || !_ctx.todos.length)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, " No Todos Yet! "))
      : (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          _createVNode(_TransitionGroup, { name: "list" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.todos, (todo) => {
                return (_openBlock(), _createBlock(_component_UiTodoCard, {
                  data: todo,
                  key: todo.id,
                  onTrash: ($event: any) => (todo.id && _ctx.toast('delete', todo.id)),
                  onFinish: ($event: any) => (todo.id && _ctx.toast($event, todo.id)),
                  onPencil: ($event: any) => (todo.id && _ctx.toast('edit', todo.id))
                }, null, 8, ["data", "onTrash", "onFinish", "onPencil"]))
              }), 128))
            ]),
            _: 1
          })
        ])),
    _createVNode(_Transition, { name: "bounce" }, {
      default: _withCtx(() => [
        _createVNode(_component_UiToast, {
          mode: _ctx.modal,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modal = 'none')),
          onTrash: _ctx.remove,
          onFinish: _ctx.toggle,
          onPencil: _ctx.edit
        }, null, 8, ["mode", "onTrash", "onFinish", "onPencil"])
      ]),
      _: 1
    })
  ]))
}