
import { PencilAltIcon, TrashIcon } from '@heroicons/vue/outline';
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		data: { type: Object, required: true },
	},
	components: { PencilAltIcon, TrashIcon },
});
