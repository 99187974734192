
export default {
	props: {
		type: {
			type: String as () => 'button' | 'submit',
			default: 'button',
			required: false,
		},
		color: {
			type: String as () => 'green' | 'gray',
			default: 'green',
			required: false,
		},
	},
};
