
import { defineComponent } from 'vue';
import { UiButton, UiToggleTheme } from '@/components';
import { changeTheme } from '@/utils';

export default defineComponent({
	data() {
		return { theme: 'dark', slideType: 'slideleft' };
	},
	mounted() {
		const val = localStorage.getItem('theme');
		val ? (this.theme = val) : (this.theme = 'dark');
		changeTheme(this.theme);
	},
	watch: {
		theme(val: string) {
			localStorage.setItem('theme', val);
			changeTheme(this.theme);
		},
	},
	methods: {
		toggleTheme() {
			this.theme = this.theme === 'dark' ? 'light' : 'dark';
		},
		setSlideType(typ: string) {
			if (['left', 'right'].includes(typ)) this.slideType = `slide${typ}`;
		},
	},
	components: { UiButton, UiToggleTheme },
});
