import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("textarea", {
      type: "text",
      id: _ctx.name,
      class: "block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-emerald-500 focus:outline-none focus:ring-0 focus:border-emerald-600 peer transition duration-500",
      placeholder: " ",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputVal) = $event))
    }, null, 8, _hoisted_2), [
      [_vModelText, _ctx.inputVal]
    ]),
    _createElementVNode("label", {
      for: _ctx.name,
      class: "absolute text-sm text-gray-500 dark:text-gray-200 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md dark:bg-slate-700 px-2 peer-focus:px-2 peer-focus:text-emerald-600 dark:peer-focus:text-emerald-200 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-95 peer-focus:-translate-y-4 left-1"
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_3)
  ]))
}