
import { defineComponent } from 'vue';

export default defineComponent({
	props: { modelValue: { type: Boolean, required: false, default: false } },
	computed: {
		inputVal: {
			get() {
				return this.modelValue;
			},
			set(val: string) {
				return this.$emit('update:modelValue', val);
			},
		},
	},
});
