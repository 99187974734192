
import { defineComponent } from 'vue';
import {
	completeToast,
	incompleteToast,
	deleteToast,
	editToast,
} from './toasts';
export default defineComponent({
	components: { completeToast, incompleteToast, deleteToast, editToast },
	props: { mode: { type: String, required: false, default: 'none' } },
	computed: {
		show() {
			return ['complete', 'incomplete', 'delete', 'edit'].includes(this.mode);
		},
	},
});
